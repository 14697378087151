import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: "ApplicationFormModule__Content__ViewOptionForm"
    autosave_interval_delay: 1000 * 60

  @selector: ".application_form_module__content[data-view-option='form']"

  @property "$form",
    get: -> @$element.find("form")

  @property "$link_to_save",
    get: -> @$element.find(".application_form_module__link_to__save")

  @property "path_to_save",
    get: -> @$link_to_save.attr("href")

  on_init: ->
    @autosave_interval = setInterval (=> @save()), @options.autosave_interval_delay

    @$element.on "click.#{@options.name}", ".application_form_module__link_to__save", (e) =>
      e.preventDefault()
      @save()

  on_destroy: ->
    @autosave_interval.clearInterval() if @autosave_interval

  save: ->
    @$link_to_save.attr("data-state", "saving")

    $.ajax
      type: "PUT"
      url: @path_to_save
      data: @$form.serialize()
      dataType: "json"
      success: (data) =>
        @$link_to_save.attr("data-state", "success")
        setTimeout (=> @$link_to_save.removeAttr("data-state")), 1000
      error: =>
        @$link_to_save.attr("data-state", "error")
        setTimeout (=> @$link_to_save.removeAttr("data-state")), 10000

Content.register()
