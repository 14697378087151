import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class HasLightbox extends Plugin
  @defaults =
    debug: false
    name: 'HasLightbox'

  @selector: "figure.has_lightbox"

  @property "$mobile_nav",
    get: -> $("html").find ".menu_module__mobile_nav"

  on_init: ->
    @$element.on "click.#{@options.name}", "img", (e) =>
      if !$(".lightbox").length && @breakpoint_check()
        $("body").append(@get_markup())
        lazySizes.autoSizer.checkElems()

  get_markup: ->
    $lightbox_element = $("<div class='lightbox'>")
    $figure_left = @$element.clone()
      # .removeClass((i, className) -> className if className.match(/modulor_image_gallery/))
    $figure_left.find(".modulor_attachment__responsive.modulor_attachment__portrait")
      .css("width", "")
      .css("padding-bottom", "#{1.0 / $figure_left.data("aspect-ratio") * 100}%")
    $figure_right = @$element.clone()
      # .removeClass((i, className) -> className if className.match(/modulor_image_gallery/))
    $figure_right.find(".modulor_attachment__responsive.modulor_attachment__portrait")
      .css("width", "")
      .css("padding-bottom", "#{1.0 / $figure_right.data("aspect-ratio") * 100}%")

    $combined_elements = $lightbox_element.append($figure_left).append($figure_right)

  breakpoint_check: ->
    if @$mobile_nav.css("display") == "none"
      return true


HasLightbox.register()
