# import Page from 'models/modulor/page/page'
#
# export class DoctoralStudent extends Modulor.Page
#   @defaults =
#     debug: false
#     name: 'DoctoralStudentPage'
#
#   @selector: ".doctoral_student_page"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# DoctoralStudent.register()
