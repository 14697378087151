# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class ProgrammeNav extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'ProgrammeNavModule'
#
#   @selector: ".programme_nav_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# ProgrammeNav.register()
