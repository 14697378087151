import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ProgrammeListModule__Content'
    selected_year_class_name: "programme_list_module__year__selected"
    selected_link_class_name: "programme_list_module__link_to__year__selected"

  @selector: ".programme_list_module__content"

  @property "year",
    get: -> @$element.attr("data-year")
    set: (val) -> @$element.attr("data-year", val)

  @property "$years",
    get: -> @$element.find(".programme_list_module__year")

  @property "$links",
    get: -> @$element.find(".programme_list_module__link_to__year")

  on_init: ->
    @$element.on "click.#{@options.name}", ".programme_list_module__link_to__year", (e) =>
      e.preventDefault()
      e.stopPropagation()
      $link = $(e.target)
      @year = $link.data("year")
      @set_selected_year()
      @set_selected_link()
      @push_state()

    @popstate_handler = @handle_popstate.bind(this)
    window.addEventListener "popstate", @popstate_handler

  on_destroy: ->
    window.removeEventListener "popstate", @popstate_handler if @popstate_handler
    @$years.removeClass(@options.selected_year_class_name)
    @$links.removeClass(@options.selected_link_class_name)

  set_selected_year: ->
    @$years.removeClass(@options.selected_year_class_name)
            .filter("[data-year='#{@year}']")
            .addClass(@options.selected_year_class_name)

  set_selected_link: ->
    @$links.removeClass(@options.selected_link_class_name)
            .filter("[data-years*='#{@year}']")
            .addClass(@options.selected_link_class_name)

  push_state: ->
    state = { year: @year }
    title = undefined
    url = new URL(window.location.origin + window.location.pathname)
    url.search = new URLSearchParams(year: @year)
    history.pushState(state, title, url)

  handle_popstate: (e) ->
    params = new URLSearchParams(window.location.search)
    year = params.get("year")
    return unless year
    @year = year
    @set_selected_year()
    @set_selected_link()

Content.register()
