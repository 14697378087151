import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class RatioToLightbox extends Plugin
  @defaults =
    debug: false
    name: 'RatioToLightbox'

  @selector: "figure.has_lightbox.modulor_attachment"

  @property "windowAspectRatio",
    get: -> window.innerWidth / window.innerHeight

  @property "aspectRatio",
    get: -> @$element.data("aspect-ratio")

  on_init: ->
    @set_orientation_in_respect_to_window_ratio()

  on_resize: ->
    @set_orientation_in_respect_to_window_ratio()

  set_orientation_in_respect_to_window_ratio: ->
    if @windowAspectRatio > @aspectRatio
      @$element.attr("data-orientation", "landscape")
    else
      @$element.attr("data-orientation", "portrait")

RatioToLightbox.register()
