import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Menu extends WebModule__Content
  @defaults =
    debug: false
    name: 'MenuModule'

  @selector: ".menu_module"

  @property "$nav",
    get: -> @$element.find '.menu_module__nav'
  @property "$mobile_nav",
    get: -> @$element.find '.menu_module__mobile_nav'
  @property "$mobile_nav_btn",
    get: -> @$element.find '.menu_module__mobile_nav__button'
  @property "$mobile_nav_btn_default_text",
    get: -> @$mobile_nav_btn.attr "data-text-inactive"
  @property "$mobile_nav_btn_active_text",
    get: -> @$mobile_nav_btn.attr "data-text-active"

  on_init: ->
    @$mobile_nav_btn.on "click.#{@options.name}", (e) =>
      if !@$element.hasClass('menu_module__content__show')
        @$mobile_nav.addClass('menu_module__mobile_nav__active')
        @$element.addClass('menu_module__content__show')
        @$nav.addClass('menu_module__nav__animate_in')
        @$nav.removeClass('menu_module__nav__animate_out')

        @set_text_mobile_nav_btn()
      else
        @$nav.addClass('menu_module__nav__animate_out')
        @$nav.removeClass('menu_module__nav__animate_in')
        @$mobile_nav.removeClass('menu_module__mobile_nav__active')

        setTimeout ( ->
          $(".menu_module__content").removeClass('menu_module__content__show')
          @$nav.removeClass('menu_module__nav__animate_out')
        ), 150

        @set_text_mobile_nav_btn()

  on_destroy: ->

  set_text_mobile_nav_btn: ->
    if @$mobile_nav.hasClass('menu_module__mobile_nav__active')
      @$mobile_nav_btn.html @$mobile_nav_btn_active_text
    else
      @$mobile_nav_btn.html @$mobile_nav_btn_default_text

  reset_text_mobile_nav_btn: ->
    @$mobile_nav_btn.html @$mobile_nav_btn_default_text

Menu.register()
