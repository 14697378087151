# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class MenuModulePage extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'MenuModulePage'
#
#   @selector: ".menu_module__menu_page"
#
#   on_init: ->
#
#   on_resize: ->
#
#   on_destroy: ->
#
# MenuModulePage.register()
