# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class Admission extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'AdmissionModule'
#
#   @selector: ".admission_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Admission.register()
