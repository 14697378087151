import Plugin from 'lib/modulor/plugin'

export class Lightbox extends Plugin
  @defaults =
    debug: false
    name: 'Lightbox'

  @selector: ".lightbox"

  on_init: ->
    $i = 0
    $("body").on "click.#{@options.name}", (e) =>
      $i++
      if $i == 2
        @$element.remove()

  on_destroy: ->

  on_keyup: (e) ->
    if e.key == 'Escape'
      @$element.remove()

Lightbox.register()
