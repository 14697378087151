import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class ItemRepeat extends Plugin
  @defaults =
    debug: false
    name: 'ItemRepeat'

  @selector: "[data-repeat]"

  @property "line_height",
    get: -> parseInt(@$element.css("line-height"))

  @property "number_of_line_repeats",
    get: -> @$element.data("repeat")

  @property "number_of_lines_in_element",
    get: -> Math.round(@element.scrollHeight / @line_height)

  @property "number_of_needed_items",
    get: -> @number_of_line_repeats * @number_of_lines_in_element - 1

  @property "$cloned_items",
    get: -> @$element.nextAll("[data-repeat-clone]")

  on_load: ->
    @repeat_items()

  on_init: ->
    @repeat_items()

  on_resize: ->
    @repeat_items()

  on_destroy: ->
    @$cloned_items.remove()

  clone_element: (index) ->
    offset = -1 * Math.floor(index / @number_of_line_repeats) * @line_height
    @$element.clone()
              .removeAttr("data-repeat")
              .attr("data-repeat-clone", true)
              .attr("data-index", index)
              .wrapInner("<div style='transform: translateY(#{offset}px)'></div>")

  repeat_items: ->
    $lastItem = @$element

    for i in [1..@number_of_needed_items]
      unless @$cloned_items.filter("[data-index='#{i}']").length > 0
        $newItem = @clone_element(i)
        $lastItem.after($newItem)
      else
        $newItem = @$cloned_items.filter("[data-index='#{i}']").first()
      $lastItem = $newItem

    @cleanup()

  cleanup: ->
    return if @$cloned_items.length <= @number_of_needed_items
    for i in [(@number_of_needed_items+1)..@$cloned_items.length]
      @$cloned_items.filter("[data-index='#{i}']").remove()

ItemRepeat.register()
