# import Page from 'models/modulor/page/page'
#
# export class IndividualProject extends Modulor.Page
#   @defaults =
#     debug: false
#     name: 'IndividualProjectPage'
#
#   @selector: ".individual_project_page"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# IndividualProject.register()
