# @see: https://github.com/turbolinks/turbolinks#full-list-of-events

import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class TurbolinksOverlay extends Plugin
  @defaults =
    debug: false
    name: 'TurbolinksOverlay'

  @selector: "html"

  @property "$toolbar_module",
    get: -> $("body").find ".toolbar_module"
  @property "$menu_module",
    get: -> @$element.find ".menu_module"
  @property "$overlay_left",
    get: -> @$element.find ".turbolinks__overlay__left"
  @property "$overlay_right",
    get: -> @$element.find ".turbolinks__overlay__right"
  @property "$mobile_nav",
    get: -> @$element.find ".menu_module__mobile_nav"
  @property "$mobile_nav_btn",
    get: -> @$element.find ".menu_module__mobile_nav__button"

  on_init: ->
    if !@$toolbar_module.length
      @$element.on "turbolinks:click.#{@options.name}", (event) =>
        @add_overlay()
        @reset_mobile_nav()

      @$element.on "turbolinks:render.#{@options.name}", (event) =>
        @remove_overlay()

  add_overlay: ->
    @$overlay_left.remove()
    @$overlay_right.remove()

    target_page_path = new URL(event.data.url).pathname.substr(1)

    left_container = "<div id='turbolinks_left' data-turbolinks-permanent class='turbolinks__overlay turbolinks__overlay__left' data-path=#{target_page_path} />"
    right_container = "<div id='turbolinks_right' data-turbolinks-permanent class='turbolinks__overlay turbolinks__overlay__right' data-path=#{target_page_path} />"

    setTimeout ( =>
      @$menu_module.prepend(left_container)
      @$overlay_left.css("height", "0")
    ), 1

    setTimeout ( =>
      @$overlay_left.css("height", "100vh")
      @$menu_module.prepend(right_container)
      @$overlay_right.css("height", "0")
    ), 100

    setTimeout ( =>
      @$overlay_right.css("height", "100vh")
    ), 200

  remove_overlay: ->
    setTimeout ( =>
      @$overlay_left.remove()
    ), 50

    setTimeout ( =>
      @$overlay_right.remove()
    ), 100

  reset_mobile_nav: ->
    @$mobile_nav.removeClass('menu_module__mobile_nav__active')

    default_text = @$mobile_nav_btn.attr "data-text-inactive"
    @$mobile_nav_btn.html default_text

TurbolinksOverlay.register()
