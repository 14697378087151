# import { default as WebModule__Content } from 'models/modulor/web_module/content'
#
# export class SupervisorHeader extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'SupervisorHeaderModule'
#
#   @selector: ".supervisor_header_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# SupervisorHeader.register()
