# import Page from 'models/modulor/page/page'
#
# export class Supervisor extends Modulor.Page
#   @defaults =
#     debug: false
#     name: 'SupervisorPage'
#
#   @selector: ".supervisor_page"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Supervisor.register()
