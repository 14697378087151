# import Page from 'models/modulor/page/page'
#
# export class Faq extends Modulor.Page
#   @defaults =
#     debug: false
#     name: 'FaqPage'
#
#   @selector: ".faq_page"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Faq.register()
